import React from "react"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <div className="md:max-w-4xl xl:max-w-6xl mx-auto mb-12 mt-3 text-my_gray flex justify-center">

      <div className="card mt-6 text-center p-6">
          <p className="text-my_yellow text-6xl font-serif">404</p>
          <p className="px-2 text-xl">
            <span className="font-semibold uppercase text-my_red tracking-wide">Error : </span>
            The requested page doesn't exists.
          </p>
      </div>

    </div>
  </>
)

export default NotFoundPage
